._3atw7S2sd9X9DfvbqLX4b- {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 3px 8px;
  height: 30px;
  line-height: 22px;
  box-sizing: border-box;
  background: none #fdfdfd;
  background: linear-gradient(to bottom, #fdfdfd 0%,#f6f7f8 100%);
  border: 1px solid #999;
  border-radius: 2px;
  color: #333;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  white-space: nowrap;
}

._3atw7S2sd9X9DfvbqLX4b-:disabled {
  cursor: not-allowed;
  background: none transparent;
}

._3atw7S2sd9X9DfvbqLX4b-:disabled > * {
  opacity: .5;
}

._27BLJMjdYnsoJWlTqUnGYL {
  display: inline-block;
  position: relative;
  /* This ensures the popover will show on top of the editor. */
  z-index: 10;
}

._3ubciuuS0EAM7JH3G8j-QV {
  padding-left: 3px;
  padding-right: 3px;
}

._9CL7yDXllYKFvnNhz-LPa {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px;
}

.zr1kxcuc2cuB_yOtaiWxA {
  background: none #d8d8d8;
}

._1RdaeqqPriOskw7YQBbTv1 {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcuODU2IDI0YzIuNjY1LTQuODMgMy4xMTUtMTIuMTk1LTcuMzU2LTExLjk1VjE4bC05LTkgOS05djUuODJDMjMuMDM4IDUuNDk1IDI0LjQzNSAxNi44OSAxNy44NTYgMjR6Ii8+PC9zdmc+");
  background-size: 14px;
}
._2UVfmR9hzOdkc0qlCQJ5Mn {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNSA1LjgyVjBsOSA5LTkgOXYtNS45NUMzLjAzIDExLjgwNiAzLjQ3OCAxOS4xNyA2LjE0NCAyNC0uNDM2IDE2Ljg5Ljk2MiA1LjQ5NCAxMy41IDUuODJ6Ii8+PC9zdmc+");
  background-size: 14px;
}

._2i-zxbKqhmPitBh-fgr5jl {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgMy4zNDRIMTR2MS4zMTNINC42NTZWMy4zNDR6bTAgNS4zMTJWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bS0yLTEuNTNxLjM3NSAwIC42NC4yNXQuMjY3LjYyNC0uMjY2LjYyNS0uNjQuMjUtLjYyNi0uMjVUMS43OCAxMnQuMjUtLjYyNS42MjYtLjI1em0wLTguMTI2cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDR0LjI5Ny0uNzIuNzAzLS4yOHptMCA0cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDh0LjI5Ny0uNzIuNzAzLS4yOHoiLz48L3N2Zz4=");
}
._39BUOR3CxSRPg8QjPw1u0R {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgOC42NTZWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bTAtOS4zMTJIMTR2MS4zMTNINC42NTZWMy4zNDR6bS0zLjMxMiA0di0uNjg4aDJ2LjYyNWwtMS4yMiAxLjM3NmgxLjIydi42ODhoLTJWOC43MmwxLjE4OC0xLjM3NkgxLjM0NHptLjY1Ni0ydi0yaC0uNjU2di0uNjg4aDEuMzEzdjIuNjg4SDJ6bS0uNjU2IDZ2LS42ODhoMnYyLjY4OGgtMnYtLjY4OGgxLjMxM3YtLjMxM0gydi0uNjg4aC42NTd2LS4zMTNIMS4zNDR6Ii8+PC9zdmc+");
}
._2o0Lb9jWIvaKGpPve90mXe {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOS4zNDQgMTEuMzQ0bDEuMzEzLTIuNjg4aC0ydi00aDR2NGwtMS4zMTMgMi42ODhoLTJ6bS01LjM0NCAwbDEuMzQ0LTIuNjg4aC0ydi00aDR2NEw2IDExLjM0NEg0eiIvPjwvc3ZnPg==");
}

.YBIbnj37Vq8uFFTIEg0qt {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOSAxMC4zNDRxLjQzOCAwIC43Mi0uMjk3dC4yOC0uNzAzLS4yOC0uNzAzVDkgOC4zNDVINi42NTZ2Mkg5em0tMi4zNDQtNnYyaDJxLjQwNiAwIC43MDMtLjI5N3QuMjk2LS43MDMtLjI5Ny0uNzAzLS43MDQtLjI5NmgtMnptMy43NSAyLjg0NHExLjQzOC42NTYgMS40MzggMi4yOCAwIDEuMDY0LS43MDMgMS43OThUOS4zNzYgMTJoLTQuNzJWMi42NTZoNC4xOXExLjEyNCAwIDEuODkuNzh0Ljc2NiAxLjkwNy0xLjA5MyAxLjg0NHoiLz48L3N2Zz4=");
}
._2NsSN8aXz7skoSk5ccijcZ {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNi42NTYgMi42NTZIMTJ2MmgtMS44NzVMNy44NzUgMTBoMS40N3YySDR2LTJoMS44NzVsMi4yNS01LjM0NGgtMS40N3YtMnoiLz48L3N2Zz4=");
}
._12oxSX6V8ykz3aX5edx-V3 {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=");
}
._1CON7t6HVVDztCgn5LRj_H {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjMuNTcgMTJxLjE5IDAgLjMxLjEydC4xMi4zMXYuODU2cTAgLjE4OC0uMTIuMzA4dC0uMzEuMTJILjQzcS0uMTg4IDAtLjMwOC0uMTJUMCAxMy4yODZ2LS44NTdxMC0uMTkuMTItLjMxVC40MjggMTJIMjMuNTd6bS0xNy4xLS44NTdxLS4zNzYtLjQ3LS42ODQtMS4wNy0uNjQzLTEuMy0uNjQzLTIuNTIgMC0yLjQyMyAxLjc5NS00LjEzNyAxLjc4LTEuNyA1LjI2My0xLjcuNjcgMCAyLjIzOC4yNTMuODg0LjE2IDIuMzcuNjQyLjEzNS41MS4yODIgMS41OC4xODggMS42NDcuMTg4IDIuNDUgMCAuMjQyLS4wNjcuNjA0bC0uMTYuMDQtMS4xMjUtLjA4LS4xODgtLjAyN3EtLjY3LTEuOTk3LTEuMzgtMi43NDctMS4xNzgtMS4yMi0yLjgxMi0xLjIyLTEuNTI3IDAtMi40MzguNzktLjg5Ny43NzgtLjg5NyAxLjk1NiAwIC45NzcuODg0IDEuODc0dDMuNzM3IDEuNzI4cS45MjUuMjY4IDIuMzE4Ljg4NC43NzcuMzc1IDEuMjcyLjY5Nkg2LjQ3em02Ljc5IDMuNDI4aDUuNTAzcS4wOTQuNTIzLjA5NCAxLjIzMyAwIDEuNDg3LS41NSAyLjg0LS4zMDcuNzM2LS45NSAxLjM5Mi0uNDk2LjQ3LTEuNDYgMS4wODUtMS4wNy42NDMtMi4wNS44ODQtMS4wNy4yOC0yLjcxOC4yOC0xLjUyOCAwLTIuNjEzLS4zMDdsLTEuODc1LS41MzZxLS43NjMtLjIxMy0uOTY0LS4zNzQtLjEwNy0uMTA3LS4xMDctLjI5NXYtLjE3M3EwLTEuNDQ2LS4wMjYtMi4wOS0uMDEzLS40IDAtLjkxbC4wMjctLjQ5NnYtLjU4OGwxLjM2Ny0uMDI3cS4yLjQ1NS40MDIuOTV0LjMuNzUuMTY3LjM2M3EuNDcuNzYzIDEuMDcgMS4yNi41NzcuNDggMS40MDcuNzYyLjc5LjI5NSAxLjc2OC4yOTUuODU3IDAgMS44NjItLjM2MiAxLjAzLS4zNDggMS42MzQtMS4xNTIuNjMtLjgxNi42My0xLjcyNyAwLTEuMTI1LTEuMDg2LTIuMTAzLS40NTUtLjM4OC0xLjgzNS0uOTV6Ii8+PC9zdmc+");
  background-size: 14px;
}
._2MVMOshvK_s1fe0AKFbPe {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTE2IDExNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDE0Ij48ZyBmaWxsLXJ1bGU9Im5vbnplcm8iPjxwYXRoIGQ9Ik0yMi40NjQgMjguNDhjMCAyLjg5NS4zNDQgNS45MDUuODA2IDkuMDIuMzQyIDMuMDEuNjkgNi4wMi42OSA4LjkxNyAwIDMuNTYyLS45MTcgNy43OS04Ljk1NSA3LjkxMnY3LjIzNmM4LjAzNi4xMTUgOC45NTYgNC42NzIgOC45NTYgNy45MTIgMCAyLjg4Ni0uMzQ4IDUuNzgzLS42OSA4Ljc4Ny0uNDYyIDMuMDEzLS44MDYgNi4xMzQtLjgwNiA4LjkyIDAgMTEuMjM4IDcuMTA2IDE1LjI1MiAxNy4wODcgMTUuMjUyaDMuMzJ2LTcuOTEyaC0yLjA2MmMtNS43MjYgMC04LjAyNS0zLjIzMy04LjAyNS04Ljc5NiAwLTIuMjM2LjM0NC00LjU3LjgwNi03LjAyMy4yMjctMi40MzguNjg0LTUuMTIuNjg0LTguMTIuMTE1LTcuNzkyLTMuMzItMTEuMjUzLTkuMTc0LTEyLjU4NnYtLjIyNWM1Ljg1NC0xLjMzMiA5LjI5My00LjY3NiA5LjE3LTEyLjQ3IDAtMi44OTUtLjQ1Ny01LjU2NS0uNjg0LTguMDI0LS40NjItMi40NC0uODA3LTQuNzc3LS44MDctNy4wMTIgMC01LjQ1IDIuMDU4LTguNjg4IDguMDI0LTguNjg4aDIuMDY2di04LjAxNGgtMy4zMmMtMTAuMjA1LS4wMDMtMTcuMDg2IDQuNDQ0LTE3LjA4NiAxNC45MTV6TTkyLjA2IDQ2LjQxN2MwLTIuODkzLjQ1My01LjkwMy44MDMtOC45MTguMzQzLTMuMTE0Ljc5Ny02LjEyLjc5Ny05LjAyIDAtMTAuNDctNi44NzUtMTQuOTE3LTE3LjA4LTE0LjkxN2gtMy4zMjd2OC4wMTdoMi4wNmM1Ljg1Mi4xMTQgNy45MSAzLjIzMyA3LjkxIDguNjg4IDAgMi4yMy0uMzQyIDQuNTY1LS42ODUgNy4wMTItLjM1IDIuNDU1LS42OTIgNS4xMjYtLjY5MiA4LjAyNC0uMTA1IDcuNzk3IDMuMzI3IDExLjEzNiA5LjA1NiAxMi40N3YuMjIyYy01LjcyIDEuMzMzLTkuMTYgNC43OTYtOS4wNTYgMTIuNTg3IDAgMyAuMzQyIDUuNjg2LjY5MiA4LjEyLjM0MyAyLjQ1NS42ODYgNC43OS42ODYgNy4wMjUgMCA1LjU1NC0yLjE4IDguNjgtNy45MTIgOC43ODhoLTIuMDZ2Ny45MTJoMy4zMjVjOS45NzUgMCAxNy4wNzYtNC4wMSAxNy4wNzYtMTUuMjUgMC0yLjc4My0uNDU0LTUuOS0uNzk2LTguOTE0LS4zNDctMy4wMS0uODA1LTUuOS0uODA1LTguNzk1IDAtMy4yMzMgMS4wMzUtNy43OSA4Ljk0My03LjkxM1Y1NC4zMmMtNy45MDQtLjExMi04LjkzNS00LjM0LTguOTM1LTcuOTAzeiIvPjwvZz48L3N2Zz4=");
}

._3kMB50gnCwFzR3GJ6_4tmQ {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIyIDI0Ij48cGF0aCBkPSJNMTkuNSAxNi4yODZxMC0uNTM2LS4zNzUtLjkxbC0yLjc4Ni0yLjc4N3EtLjM3Ni0uMzc2LS45MTItLjM3Ni0uNTYzIDAtLjk2NC40M2wuMjU0LjI0N3EuMjE0LjIwOC4yODguMjl0LjIuMjUzLjE3NS4zNDIuMDQ4LjM2OHEwIC41MzYtLjM3NS45MXQtLjkxLjM3NnEtLjIwMiAwLS4zNy0uMDQ4dC0uMzQtLjE3NC0uMjU1LS4yLS4yODgtLjI5LS4yNDgtLjI1M3EtLjQ0Mi40MTUtLjQ0Mi45NzggMCAuNTM2LjM3NS45MWwyLjc2IDIuNzczcS4zNi4zNjIuOTEuMzYyLjUzNiAwIC45MS0uMzQ4bDEuOTctMS45NTVxLjM3NS0uMzc1LjM3NS0uODk3em0tOS40MTUtOS40NDJxMC0uNTM2LS4zNzUtLjkxTDYuOTUgMy4xNnEtLjM3NC0uMzc0LS45MS0uMzc0LS41MjIgMC0uOTEuMzYyTDMuMTYgNS4xMDNxLS4zNzUuMzc1LS4zNzUuODk3IDAgLjUzNi4zNzUuOTFsMi43ODYgMi43ODdxLjM2Mi4zNjIuOTEuMzYyLjU2NCAwIC45NjUtLjQxNmwtLjI1My0uMjQ4cS0uMjEzLS4yMDgtLjI4OC0uMjg4dC0uMjAyLS4yNTQtLjE3NC0uMzQyLS4wNDctLjM2OHEwLS41MzYuMzc1LS45MXQuOTEtLjM3NnEuMjAyIDAgLjM3LjA0N3QuMzQuMTc0LjI1NS4yLjI4OC4yODguMjQ4LjI1NHEuNDQyLS40MTUuNDQyLS45Nzh6bTExLjk4NiA5LjQ0MnEwIDEuNjA3LTEuMTM3IDIuNzJsLTEuOTcgMS45NTRxLTEuMTEgMS4xMTItMi43MTggMS4xMTItMS42MiAwLTIuNzMyLTEuMTM4bC0yLjc2LTIuNzcycS0xLjExLTEuMTEyLTEuMTEtMi43MiAwLTEuNjQ2IDEuMTc4LTIuNzk4bC0xLjE3OC0xLjE4cS0xLjE1MiAxLjE4LTIuNzg2IDEuMTgtMS42MDcgMC0yLjczMi0xLjEyNUwxLjMzOCA4LjczMlEuMjEzIDcuNjA4LjIxMyA2VDEuMzUgMy4yODNsMS45Ny0xLjk1NVE0LjQzMi4yMTUgNi4wNC4yMTVxMS42MiAwIDIuNzMgMS4xMzhsMi43NiAyLjc3MnExLjExMiAxLjExMiAxLjExMiAyLjcyIDAgMS42NDYtMS4xOCAyLjc5OGwxLjE4IDEuMThxMS4xNTItMS4xOCAyLjc4Ni0xLjE4IDEuNjA3IDAgMi43MzIgMS4xMjVsMi43ODYgMi43ODZxMS4xMjUgMS4xMjUgMS4xMjUgMi43MzJ6Ii8+PC9zdmc+");
  background-size: 14px;
}

._3pyM56GxfzLlQutjsFK9H6 {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIyIDI0Ij48cGF0aCBkPSJNNS44OCAxNy4wMjJsLTMuNDMgMy40M3EtLjEzNC4xMi0uMzA4LjEyLS4xNiAwLS4zMDgtLjEyLS4xMi0uMTM1LS4xMi0uMzF0LjEyLS4zMDdsMy40My0zLjQzcS4xMzMtLjEyLjMwNy0uMTJ0LjMxLjEycS4xMi4xMzUuMTIuMzF0LS4xMi4zMDd6bTIuMjYzLjU1djQuMjg1cTAgLjE4OC0uMTIuMzA4dC0uMzEuMTItLjMwNy0uMTItLjEyLS4zMDhWMTcuNTdxMC0uMTg3LjEyLS4zMDd0LjMwOC0uMTIuMzA4LjEyLjEyLjMwOHptLTMtM3EwIC4xODctLjEyLjMwN3QtLjMxLjEySC40M3EtLjE4OCAwLS4zMDgtLjEyVDAgMTQuNTd0LjEyLS4zMDcuMzA4LS4xMmg0LjI4NnEuMTg4IDAgLjMwOC4xMnQuMTIuMzA4em0xNi45MjggMS43MTRxMCAxLjYwNy0xLjEzNyAyLjcybC0xLjk3IDEuOTU0cS0xLjExIDEuMTEyLTIuNzE4IDEuMTEyLTEuNjIgMC0yLjczMi0xLjEzOEw5LjA0IDE2LjQ0N3EtLjI4LS4yOC0uNTYzLS43NWwzLjItLjI0IDMuNjU3IDMuNjdxLjM2Mi4zNi45MS4zNjd0LjkxMi0uMzU1bDEuOTctMS45NTZxLjM3NC0uMzc1LjM3NC0uODk3IDAtLjUzNi0uMzc1LS45MWwtMy42Ny0zLjY4NC4yNC0zLjJxLjQ3LjI4Ljc1LjU2Mmw0LjUgNC41cTEuMTI2IDEuMTUyIDEuMTI2IDIuNzMyek0xMy44MSA2LjU5bC0zLjIuMjRMNi45NSAzLjE2cS0uMzc0LS4zNzUtLjkxLS4zNzUtLjUyMiAwLS45MS4zNjJMMy4xNiA1LjEwMnEtLjM3NS4zNzUtLjM3NS44OTcgMCAuNTM1LjM3NS45MWwzLjY3IDMuNjctLjI0IDMuMjE0cS0uNDctLjI4LS43NS0uNTYzbC00LjUtNC41US4yMTMgNy41OC4yMTMgNnEwLTEuNjA4IDEuMTM4LTIuNzJsMS45Ny0xLjk1NVE0LjQzLjIxMyA2LjA0LjIxM3ExLjYyIDAgMi43MzIgMS4xMzhsNC40NzMgNC40ODhxLjI4LjI4LjU2My43NXptOC40NzggMS4xMjRxMCAuMTg4LS4xMi4zMDh0LS4zMS4xMmgtNC4yODVxLS4xODcgMC0uMzA3LS4xMnQtLjEyLS4zMDguMTItLjMwOC4zMDgtLjEyaDQuMjg3cS4xODggMCAuMzA4LjEydC4xMi4zMDh6TTE1IC40M3Y0LjI4NXEwIC4xODgtLjEyLjMwOHQtLjMxLjEyLS4zMDctLjEyLS4xMi0uMzA4Vi40M3EwLS4xOS4xMi0uMzFUMTQuNTcgMHQuMzEuMTIuMTIuMzF6bTUuNDUgMi4wMmwtMy40MjggMy40M3EtLjE0Ny4xMi0uMzA4LjEydC0uMzA4LS4xMnEtLjEyLS4xMzQtLjEyLS4zMDh0LjEyLS4zMDhsMy40My0zLjQzcS4xMzMtLjEyLjMwNy0uMTJ0LjMwOC4xMnEuMTIyLjEzNS4xMjIuMzF0LS4xMi4zMDd6Ii8+PC9zdmc+");
  background-size: 14px;
}

.lBd3h6_uq1lhwnIo4Z1XQ {
  background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTMzLjMzMyA1MzMuMzM0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MzMuMzMzIDUzMy4zMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNNDY2LjY2NywxMDBoLTQwMHYzMzMuMzMzaDQwMFYxMDB6IE01MzMuMzMzLDMzLjMzM0w1MzMuMzMzLDMzLjMzM1Y1MDBIMFYzMy4zMzNINTMzLjMzM3ogTTQzMy4zMzMsNDAwSDEwMHYtNjYuNjY3ICAgbDEwMC0xNjYuNjY3bDEzNi45NzksMTY2LjY2N2w5Ni4zNTQtNjYuNjY2VjMwMFY0MDB6IE0zMzMuMzMzLDE4My4zMzNjMCwyNy42MTQsMjIuMzg2LDUwLDUwLDUwczUwLTIyLjM4Niw1MC01MHMtMjIuMzg2LTUwLTUwLTUwICAgUzMzMy4zMzMsMTU1LjcxOSwzMzMuMzMzLDE4My4zMzN6IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==");
  background-size: 14px;
}

._2KCIf4i1hJA6V_pxDIQm3L {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjMuNzggMTkuMjhMMTYuNSAxMmw3LjI4LTcuMjhhLjc0OC43NDggMCAwIDAgMC0xLjA2TDIwLjM0LjIxOGEuNzUuNzUgMCAwIDAtMS4wNi0uMDAyTDEyIDcuNDk4IDQuNzE3LjIyYS43NDguNzQ4IDAgMCAwLTEuMDYgMEwuMjE3IDMuNjZhLjc1Ljc1IDAgMCAwIDAgMS4wNkw3LjQ5NyAxMmwtNy4yOCA3LjI4YS43NDguNzQ4IDAgMCAwIDAgMS4wNmwzLjQ0IDMuNDRhLjc1Ljc1IDAgMCAwIDEuMDYuMDAybDcuMjgtNy4yOCA3LjI4MiA3LjI4Yy4wNzguMDc4LjE3LjEzNS4yNjguMTcuMjY3LjEuNTguMDQ0Ljc5My0uMTdsMy40NC0zLjQ0YS43NS43NSAwIDAgMCAwLTEuMDZ6Ii8+PC9zdmc+");
  background-size: 13px;
}

._2qMrA34MOcE5CEMEVKk3B1 {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAuMjUgM0w5IDE0LjI1IDMuNzUgOSAwIDEyLjc1bDkgOSAxNS0xNXoiLz48L3N2Zz4=");
  background-size: 13px;
}

._3n3-Bvu4PyLV9Pk4V5dX7y {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 5px 0;
  white-space: nowrap;
}

._3n3-Bvu4PyLV9Pk4V5dX7y:last-child {
  margin-right: 0;
}

/* TODO: remove all this child selector and tag selector stuff. */

._3n3-Bvu4PyLV9Pk4V5dX7y > div > button {
  margin-right: 0;
  border-radius: 0;
}

._3n3-Bvu4PyLV9Pk4V5dX7y > div > button:focus {
  position: relative;
  z-index: 1;
}

._3n3-Bvu4PyLV9Pk4V5dX7y > div:first-child > button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

._3n3-Bvu4PyLV9Pk4V5dX7y > div + div > button {
  border-left-width: 0;
}

._3n3-Bvu4PyLV9Pk4V5dX7y > div:last-child > button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.BkRF-SRIFd1m1LiGCHVZW {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 260px;
  background: none #fdfdfd;
  background: linear-gradient(to bottom, #fdfdfd 0%,#f6f7f8 100%);
  border: 1px solid #999;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 4px;
}

.BkRF-SRIFd1m1LiGCHVZW:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 10px;
  border: 5px solid transparent;
  border-bottom-color: #999;
}

.BkRF-SRIFd1m1LiGCHVZW:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  left: 10px;
  border: 5px solid transparent;
  border-bottom-color: #fdfdfd;
}

._2eDG8zJPY934SIq6zHvbKD {
  display: -webkit-flex;
  display: flex;
}

._3ao32xsP2H2Vzuzg4PP1FG {
  display: block;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  height: 30px;
  background: none white;
  border: 1px solid #999;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 2px 6px;
  font-family: inherit;
  font-size: inherit;
  line-height: 24px;
}

._2eDG8zJPY934SIq6zHvbKD .AD290w8hNa7mBhrQ2TWYz {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-left: 4px;
  margin-bottom: 0;
}

._33ZpUWOUUvw68ACdoLUBdM {
  display: inline-block;
  position: relative;
  line-height: 22px;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._33ZpUWOUUvw68ACdoLUBdM select {
  position: relative;
  z-index: 2;
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: 4px solid transparent;
  border-right-width: 10px;
  border-left-width: 5px;
  background: none transparent;
  opacity: 0;
  cursor: pointer;
}

._33ZpUWOUUvw68ACdoLUBdM ._2Cb8jxhaogHPpH1Y_fRRpC {
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 23px;
  border: 1px solid #999;
  border-radius: 2px;
  padding: 3px;
  padding-right: 33px;
  padding-left: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._33ZpUWOUUvw68ACdoLUBdM ._2Cb8jxhaogHPpH1Y_fRRpC::before,
._33ZpUWOUUvw68ACdoLUBdM ._2Cb8jxhaogHPpH1Y_fRRpC::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
}

._33ZpUWOUUvw68ACdoLUBdM ._2Cb8jxhaogHPpH1Y_fRRpC::before {
  margin-top: -10px;
  border-bottom-color: #555;
}

._33ZpUWOUUvw68ACdoLUBdM ._2Cb8jxhaogHPpH1Y_fRRpC::after {
  margin-top: 1px;
  border-top-color: #555;
}

._33ZpUWOUUvw68ACdoLUBdM select:focus + ._2Cb8jxhaogHPpH1Y_fRRpC {
  border-color: #66afe9;
}

/* On Webkit we can style <select> to be transparant without turning off the
   default focus styles. This is better for accessibility. */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  ._33ZpUWOUUvw68ACdoLUBdM select {
    opacity: 1;
    color: inherit;
    -webkit-appearance: none;
    border-left-width: 12px;
    border-right-width: 35px;
  }

  ._33ZpUWOUUvw68ACdoLUBdM select + ._2Cb8jxhaogHPpH1Y_fRRpC {
    color: transparent;
  }

  ._33ZpUWOUUvw68ACdoLUBdM select:focus + ._2Cb8jxhaogHPpH1Y_fRRpC {
    border-color: #999;
  }
}

._2j51rWdCSKQuxa-a-WjES_ {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin: 0 10px;
  padding: 10px 0 5px;
  border-bottom: 1px solid #ddd;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._1yT5RWbh_XpBMk7dAaZmzQ {
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
}

.KmpIrxG43vI--Yx7gNPHK {
  border: 1px dashed #78a300;
  position: relative;
  max-width: 100%;
  display: inline-block;
  line-height: 0;
  top: -1px;
  left: -1px;
}

.p1wObypCR69FeQgkYlVaN {
  cursor: nwse-resize;
  position: absolute;
  z-index: 2;
  line-height: 1;
  bottom: -4px;
  right: -5px;
  border: 1px solid white;
  background-color: #78a300;
  width: 8px;
  height: 8px;
}

/**
 * We inherit the height of the container by default
 */

.YGvS67RHgbSbPF4EB-0K2,
._3n2QWBn5_7wOFZnEux0N-f,
._2pNO0EvmtAZeMnBoJtCKUO {
  height: inherit;
  text-align: left;
  text-align: initial;
}

.YGvS67RHgbSbPF4EB-0K2 {
  position: relative;
}

/**
 * Zero-opacity background used to allow focus in IE. Otherwise, clicks
 * fall through to the placeholder.
 */

._3n2QWBn5_7wOFZnEux0N-f {
  background-color: rgba(255, 255, 255, 0);
  /* Repair mysterious missing Safari cursor */
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
}

._2pNO0EvmtAZeMnBoJtCKUO {
  outline: none;
  white-space: pre-wrap;
}

._3WBx2ROi01yCi9eE0IJPOm {
  position: relative;
}

.qez-YYe6sKw8ha690nI6Q ._3WBx2ROi01yCi9eE0IJPOm {
  text-align: left;
}

.qez-YYe6sKw8ha690nI6Q .-s_44m8XMkd1yNcD1PlUG {
  left: 0;
  text-align: left;
}

._3zTBdn602AAP_rWgyVuv1c ._3WBx2ROi01yCi9eE0IJPOm {
  text-align: center;
}

._3zTBdn602AAP_rWgyVuv1c .-s_44m8XMkd1yNcD1PlUG {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.UPes16LAa6j_RNOM0I1HY ._3WBx2ROi01yCi9eE0IJPOm {
  text-align: right;
}

.UPes16LAa6j_RNOM0I1HY .-s_44m8XMkd1yNcD1PlUG {
  right: 0;
  text-align: right;
}
/**
 * @providesModule DraftEditorPlaceholder
 */

.-s_44m8XMkd1yNcD1PlUG {
  color: #9197a3;
  position: absolute;
  z-index: 0;
}

._1GvbKNTwR5edvjm5VvWWZY {
  color: #bdc1c9;
}

._2J08GMR8NHT13FMVhu6hHk {
  display: none;
}
/**
 * @providesModule DraftStyleDefault
 */

._2Fu4UbHMfGpHq2B4pePZTY {
  position: relative;
  white-space: pre-wrap;
}

/* @noflip */

._3OFdImuqqS8w9E5PS0gjNT {
  direction: ltr;
  text-align: left;
}

/* @noflip */

._2W8d6js2zeFsc0Xs-Pk8kV {
  direction: rtl;
  text-align: right;
}

/**
 * These rules provide appropriate text direction for counter pseudo-elements.
 */

/* @noflip */

._1uhoGoaCxhP0Tr7eolwE4o {
  direction: ltr;
}

/* @noflip */

._18A4cD88_vJ55En39wRTAl {
  direction: rtl;
}

/**
 * Default spacing for list container elements. Override with CSS as needed.
 */

.qovvbJDzsGuUPbuT8oavf,
._3LWnNKOOv0wZmvsWyVglR- {
  margin: 16px 0;
  padding: 0;
}

/**
 * Default counters and styles are provided for five levels of nesting.
 * If you require nesting beyond that level, you should use your own CSS
 * classes to do so. If you care about handling RTL languages, the rules you
 * create should look a lot like these.
 */

/* @noflip */

._5q4msQu8FmI2-d1Q63ec4._1uhoGoaCxhP0Tr7eolwE4o {
  margin-left: 1.5em;
}

/* @noflip */

._5q4msQu8FmI2-d1Q63ec4._18A4cD88_vJ55En39wRTAl {
  margin-right: 1.5em;
}

/* @noflip */

._16UTzBA0cAnP18TRQbXUtq._1uhoGoaCxhP0Tr7eolwE4o {
  margin-left: 3em;
}

/* @noflip */

._16UTzBA0cAnP18TRQbXUtq._18A4cD88_vJ55En39wRTAl {
  margin-right: 3em;
}

/* @noflip */

.j4UjLAU7zjb8CWG1TcNhX._1uhoGoaCxhP0Tr7eolwE4o {
  margin-left: 4.5em;
}

/* @noflip */

.j4UjLAU7zjb8CWG1TcNhX._18A4cD88_vJ55En39wRTAl {
  margin-right: 4.5em;
}

/* @noflip */

._2L_EdSJNAviOv14G-J37bo._1uhoGoaCxhP0Tr7eolwE4o {
  margin-left: 6em;
}

/* @noflip */

._2L_EdSJNAviOv14G-J37bo._18A4cD88_vJ55En39wRTAl {
  margin-right: 6em;
}

/* @noflip */

._2ZlhdQ5A34XD0gIMCsAf1v._1uhoGoaCxhP0Tr7eolwE4o {
  margin-left: 7.5em;
}

/* @noflip */

._2ZlhdQ5A34XD0gIMCsAf1v._18A4cD88_vJ55En39wRTAl {
  margin-right: 7.5em;
}

/**
 * Only use `square` list-style after the first two levels.
 */

._1KGn1ZXYbzTBeMNWJNPQ4G {
  list-style-type: square;
  position: relative;
}

._1KGn1ZXYbzTBeMNWJNPQ4G._5q4msQu8FmI2-d1Q63ec4 {
  list-style-type: disc;
}

._1KGn1ZXYbzTBeMNWJNPQ4G._16UTzBA0cAnP18TRQbXUtq {
  list-style-type: circle;
}

/**
 * Ordered list item counters are managed with CSS, since all list nesting is
 * purely visual.
 */

._1Jn3JRo3is-K5AryEd2EfX {
  list-style-type: none;
  position: relative;
}

/* @noflip */

._1Jn3JRo3is-K5AryEd2EfX._1uhoGoaCxhP0Tr7eolwE4o:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}

/* @noflip */

._1Jn3JRo3is-K5AryEd2EfX._18A4cD88_vJ55En39wRTAl:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}

/**
 * Counters are reset in JavaScript. If you need different counter styles,
 * override these rules. If you need more nesting, create your own rules to
 * do so.
 */

._1Jn3JRo3is-K5AryEd2EfX:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

._1Jn3JRo3is-K5AryEd2EfX._16UTzBA0cAnP18TRQbXUtq:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

._1Jn3JRo3is-K5AryEd2EfX.j4UjLAU7zjb8CWG1TcNhX:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

._1Jn3JRo3is-K5AryEd2EfX._2L_EdSJNAviOv14G-J37bo:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

._1Jn3JRo3is-K5AryEd2EfX._2ZlhdQ5A34XD0gIMCsAf1v:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

._5q4msQu8FmI2-d1Q63ec4._3R56jUqn7chva9KZumiqU5 {
  counter-reset: ol0;
}

._16UTzBA0cAnP18TRQbXUtq._3R56jUqn7chva9KZumiqU5 {
  counter-reset: ol1;
}

.j4UjLAU7zjb8CWG1TcNhX._3R56jUqn7chva9KZumiqU5 {
  counter-reset: ol2;
}

._2L_EdSJNAviOv14G-J37bo._3R56jUqn7chva9KZumiqU5 {
  counter-reset: ol3;
}

._2ZlhdQ5A34XD0gIMCsAf1v._3R56jUqn7chva9KZumiqU5 {
  counter-reset: ol4;
}

._3vaJLLYwoKFbLkwhm7VZGD {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: 'Georgia', serif;
  font-size: 14px;
}

._1XFl4IeA-fyEI9QMzFK3cm {
  cursor: text;
  font-size: 16px;
}

._1XFl4IeA-fyEI9QMzFK3cm .public-DraftEditorPlaceholder-root,
._1XFl4IeA-fyEI9QMzFK3cm .public-DraftEditor-content {
  margin: 0;
  /* 1px is added as transparent border on .DraftEditor-editorContainer */
  padding: 9px;
}

._1XFl4IeA-fyEI9QMzFK3cm .public-DraftEditor-content {
  overflow: auto;
}

._3Ej3qUbp4o4cFongitVC97 .public-DraftEditorPlaceholder-root {
  display: none;
}

._1XFl4IeA-fyEI9QMzFK3cm ._20x9t-BL2SDh7lOGEew66E {
  margin: 14px 0;
}

/* Consecutive code blocks are nested inside a single parent <pre> (like <li>
  inside <ul>). Unstyle the parent and style the children. */
._1XFl4IeA-fyEI9QMzFK3cm pre {
  margin: 14px 0;
}

._1XFl4IeA-fyEI9QMzFK3cm ._31eOKDaiBJGRsXkL-mX7P9 {
  background-color: #f3f3f3;
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  /* This should collapse with the margin around the parent <pre>. */
  margin: 14px 0;
  padding: 20px;
}

/* Hacky: Remove padding from inline <code> within code block. */
._1XFl4IeA-fyEI9QMzFK3cm ._31eOKDaiBJGRsXkL-mX7P9 span[style] {
  padding: 0 !important;
}

._1XFl4IeA-fyEI9QMzFK3cm ._4rkAPeLa5vNw7RcexJ3_C {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

/* There shouldn't be margin outside the first/last blocks */
._1XFl4IeA-fyEI9QMzFK3cm .DK2WGA7X3rKos2v1AABBD:first-child,
._1XFl4IeA-fyEI9QMzFK3cm pre:first-child,
._1XFl4IeA-fyEI9QMzFK3cm ul:first-child,
._1XFl4IeA-fyEI9QMzFK3cm ol:first-child {
  margin-top: 0;
}
._1XFl4IeA-fyEI9QMzFK3cm .DK2WGA7X3rKos2v1AABBD:last-child,
._1XFl4IeA-fyEI9QMzFK3cm pre:last-child,
._1XFl4IeA-fyEI9QMzFK3cm ul:last-child,
._1XFl4IeA-fyEI9QMzFK3cm ol:last-child {
  margin-bottom: 0;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em; }

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: ''; }

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc; }

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent; }

.daterangepicker.opensleft:before {
  right: 9px; }

.daterangepicker.opensleft:after {
  right: 10px; }

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.daterangepicker.opensright:before {
  left: 9px; }

.daterangepicker.opensright:after {
  left: 10px; }

.daterangepicker.drop-up {
  margin-top: -7px; }

.daterangepicker.drop-up:before {
  top: auto;
  top: initial;
  bottom: -7px;
  border-bottom: 0;
  border-bottom: initial;
  border-top: 7px solid #ccc; }

.daterangepicker.drop-up:after {
  top: auto;
  top: initial;
  bottom: -6px;
  border-bottom: 0;
  border-bottom: initial;
  border-top: 6px solid #fff; }

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none; }

.daterangepicker.single .drp-selected {
  display: none; }

.daterangepicker.show-calendar .drp-calendar {
  display: block; }

.daterangepicker.show-calendar .drp-buttons {
  display: block; }

.daterangepicker.auto-apply .drp-buttons {
  display: none; }

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px; }

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px; }

.daterangepicker .drp-calendar.right {
  padding: 8px; }

.daterangepicker .drp-calendar.single .calendar-table {
  border: none; }

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px; }

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer; }

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff; }

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse; }

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit; }

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc; }

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999; }

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0; }

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px; }

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0; }

.daterangepicker td.start-date.end-date {
  border-radius: 4px; }

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff; }

.daterangepicker th.month {
  width: auto; }

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through; }

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default; }

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%; }

.daterangepicker select.yearselect {
  width: 40%; }

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px; }

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative; }

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed; }

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle; }

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px; }

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px; }

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd; }

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd; }

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0; }

.daterangepicker.show-calendar .ranges {
  margin-top: 8px; }

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%; }

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer; }

.daterangepicker .ranges li:hover {
  background-color: #eee; }

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto; }
  .daterangepicker .ranges ul {
    width: 140px; }
  .daterangepicker.single .ranges ul {
    width: 100%; }
  .daterangepicker.single .drp-calendar.left {
    clear: none; }
  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left; }
  .daterangepicker {
    direction: ltr;
    text-align: left; }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0; }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .daterangepicker .drp-calendar.right {
    margin-left: 0; }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px; }
  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left; } }

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto; }
  .daterangepicker .ranges {
    float: left; }
  .daterangepicker.rtl .ranges {
    float: right; }
  .daterangepicker .drp-calendar.left {
    clear: none !important; } }

/**
* Rangeslider
*/
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  touch-action: none; }

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4); }

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4); }

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1; }

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%; }

.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0; }

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px; }

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0; }

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0); }

.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset; }

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px; }

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent; }

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute; }

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0; }

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none; }

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0); }

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px; }

/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0; }

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit; }

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative; }

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px; }

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: '';
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1; }

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

